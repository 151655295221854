import "./ButtonWithIcon.css";
export const ButtonWithIcon = (props) => {
  const { icon, text, onClick } = props;
  return (
    <div >
      <div className="button-with-icon" onClick={onClick}>
        <span style={{paddingLeft:10}}>{icon}</span>
        <span style={{paddingLeft:10}}>{text}</span>
      </div>
    </div>
  );
};

export default ButtonWithIcon;
