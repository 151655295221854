import { Col, message, Row, Spin, Radio } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import React, { useEffect, useState } from 'react'
import "./HomePage.css"
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import { urlCreateOrder, urlGetInternalUseForUser, urlGetPartListForUser } from "services/CONSTANTS";
import { axiosGet, axiosPost } from "services/ajaxServices";
import { apiGetfileSearch, apiGetFolderList } from "services/folderListService";
import { FolderOpenOutlined, FolderOutlined } from "@ant-design/icons";
import { UpdateCurrentRoot } from "redux/actions/appActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { ROUTE_FILE_LIST } from "navigation/CONSTANTS";
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { CSSTransition } from "react-transition-group";
import { icon_backUrl, icon_fileUrl, icon_folderUrl, icon_homeUrl } from "config/CONSTANTS";
import { apiGetfileList } from "services/fileListService";


function HomePage() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const [showFileList, setShowFileList] = useState(true);
    const [showSearchResult, setShowSearchResult] = useState(false);

    const [showFile, setShowFile] = useState(false);
    const [fileInfo, setFileInfo] = useState({});
    const [folderInfo, setFolderInfo] = useState({});


    useEffect(() => {
        setIsBusy(true)
        let mounted = true;
        setTimeout(() => {
            if (mounted) {
                apiGetFolderList()
                    .then((data) => {
                        setDataList(data)
                        setIsBusy(false)
                    })
                    .catch((err) => {
                        setIsBusy(false)
                        // message.error("Sorry! " + err);
                    });
            }
        }, 50);

        return () => mounted = false;
    }, []);

    const onPressEnter :SearchProps['onSearch'] = (value, _e, info) => {
        processSearchAction(value.target.value)
    }
    const handleSearch = (searchText) => {
        processSearchAction(searchText)
    };

    const processSearchAction = (searchText) => {
        setIsBusy(true)
        setFolderInfo({})
        if (searchText) {
            apiGetfileSearch(searchText)
                .then((data) => {
                    setIsBusy(false)
                    setShowSearchResult(true)
                    setSearchList(data)
                })
                .catch((err) => {
                    setIsBusy(false)
                    // message.error("Sorry! " + err);
                });
        }
        else {
            setShowSearchResult(false)
            setSearchList([])
            apiGetFolderList()
                .then((data) => {
                    setDataList(data)
                    setIsBusy(false)
                })
                .catch((err) => {
                    setIsBusy(false)
                    // message.error("Sorry! " + err);
                });
        }
    }
    const clickOneFileAction = (info) => {
        setFileInfo(info);
        setShowFile(true)
    };
    const clickFolderAction = (info) => {
        dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_FILE_LIST, folder_info: info }))
        history.push(ROUTE_FILE_LIST + "/" + info.id)
        // setIsBusy(true)
        // setFolderInfo(info)
        // apiGetfileList(info.id)
        //             .then((data) => {
        //                 setIsBusy(false)
        //                 setShowSearchResult(true)
        //                 setSearchList(data)
        //             })
        //             .catch((err) => {
        //                 setIsBusy(false)
        //                 // message.error("Sorry! " + err);
        //             });
    };
    return (
        <Spin spinning={isBusy}>
            <div>
                <div className="folder-search">
                    <Row align={'center'}>
                        <Col xs={20} sm={20} md={12} lg={8} xl={8} >
                            <TitleSearch
                                placeholder="Search Keyword"
                                onSearch={handleSearch}
                                onPressEnter={onPressEnter}
                            />
                        </Col>
                    </Row>
                </div>
                {!showSearchResult && (<div><Row align={'center'} style={{ textAlign: 'center' }}>
                    <h1>Wait Industries Document Library</h1>
                </Row>
                    <Row>
                        {dataList == undefined || dataList.length == 0 ? <div style={{ margin: "auto" }}></div> : dataList.map((info, index) => {
                            return <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} onClick={() => {
                                clickFolderAction(info)
                            }}>
                                <div className="folder-item">
                                    <img src={icon_folderUrl} className="folder-image"></img>
                                    <div className="folder-title">
                                        {info.name}
                                    </div>
                                    <div className="folder-description">
                                        {info.description}
                                    </div>
                                </div>
                            </Col>
                        })}
                    </Row></div>)}
            </div>

            <div>
                {showSearchResult && showFileList && (
                    <div>
                        <Row align={'center'}>
                            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                                <ButtonWithIcon
                                    icon={<img src={icon_backUrl} />}
                                    alt=""
                                    text="Previous"
                                    onClick={() => {
                                        history.replace("/");
                                        setShowSearchResult(false)
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                                <div style={{ textAlign: "center"}}>
                                    {folderInfo.name == undefined || folderInfo.name.length == 0 ?<h1 style={{ fontSize: 16 }}>Search Result</h1> : <h1>{folderInfo.name}</h1>} 
                                </div>
                            </Col>
                            <Col xs={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 8, order: 3 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                                <div style={{ float: "right", paddingRight: 20 }}>
                                    <ButtonWithIcon
                                        icon={<img src={icon_homeUrl} />}
                                        alt=""
                                        text="Home"
                                        onClick={() => {
                                            history.replace("/");
                                            window.location.reload();
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {searchList == undefined || searchList.length == 0 ? <div style={{ margin: "auto" }}>You don't have any result</div> : searchList.map((info, index) => {
                                return <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} onClick={() => {
                                    clickOneFileAction(info)
                                }}>
                                    <div className="file-item">
                                        <img src={icon_fileUrl} className="file-image"></img>
                                        <div className="file-title">
                                            {info.name}
                                        </div>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </div>
                )}
                <CSSTransition
                    in={showFile}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                    onEnter={() => setShowFileList(false)}
                    onExited={() => setShowFileList(true)}
                >
                    <div>
                        <Row align={'center'}>
                            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                                <ButtonWithIcon
                                    icon={<img src={icon_backUrl} />}
                                    alt=""
                                    text="Previous"
                                    onClick={() => {
                                        setShowFile(false)
                                    }}
                                />
                            </Col>
                            <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                                <div style={{ textAlign: "center", fontSize: 10 }}>
                                    <h1>{fileInfo.name}</h1>
                                </div>
                            </Col>
                            <Col xs={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 8, order: 3 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                                <div style={{ float: "right", paddingRight: 20 }}>
                                    <ButtonWithIcon
                                        icon={<img src={icon_homeUrl} />}
                                        alt=""
                                        text="Home"
                                        onClick={() => {
                                            history.replace("/");
                                            window.location.reload();
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className="pdf-reader">
                            <iframe src={fileInfo.file_link}
                                width="100%" height="100%" />
                        </div>
                        {/* <div>
                        <Document file={{ url: fileInfo.file_link, httpHeaders: {}, withCredentials: false }} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <p>
                            Page {pageNumber} of {numPages}
                        </p>
                    </div> */}
                    </div>

                </CSSTransition>
            </div>

        </Spin>
    );
}

export default HomePage
