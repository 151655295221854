import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React from "react";
import { MetaTags } from "react-meta-tags";
import HeaderWidget from "./HeaderWidget";
import HomePage from "./HomePage";

const DashboardPage = () => {
  
  return (
    <div>
      <HomePage />
    </div>
  );
};

export default DashboardPage;
