import { Col, message, Row, Spin, Radio, Button } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import React, { useEffect, useState } from 'react'
import "./FileListPage.css"
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import { urlCreateOrder, urlGetInternalUseForUser, urlGetPartListForUser } from "services/CONSTANTS";
import { axiosGet, axiosPost } from "services/ajaxServices";
import { ArrowLeftOutlined, DownloadOutlined, FolderOpenOutlined, FolderOutlined } from "@ant-design/icons";
import { icon_backUrl, icon_fileUrl, icon_folderUrl, icon_homeUrl } from "config/CONSTANTS";
import { UpdateCurrentRoot } from "redux/actions/appActions";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { apiGetfileList, apiGetSearchfileList } from "services/fileListService";
import { CSSTransition } from "react-transition-group";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { ROOT, ROUTE_HOME } from "navigation/CONSTANTS";


function FileListPage() {
    const { folder_id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [showFileList, setShowFileList] = useState(true);
    const [showFile, setShowFile] = useState(false);
    const [fileInfo, setFileInfo] = useState({});

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const appDataStore = useSelector(x => x.appDataStore);
    const { folder_info } = appDataStore;

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        setIsBusy(true)
        let mounted = true;
        setTimeout(() => {
            if (mounted) {
                apiGetfileList(folder_id)
                    .then((data) => {
                        setSearchList(data)
                        setDataList(data)
                        setIsBusy(false)
                    })
                    .catch((err) => {
                        setIsBusy(false)
                        // message.error("Sorry! " + err);
                    });
            }
        }, 50);

        return () => mounted = false;
    }, []);


    const onPressEnter: SearchProps['onSearch'] = (value, _e, info) => {
        processSearchAction(value.target.value)
    }
    const handleSearch = (searchText) => {
        processSearchAction(searchText)
    };

    const processSearchAction = (searchText) => {
        setIsBusy(true)
        if (searchText) {
            apiGetSearchfileList(folder_id, searchText)
                .then((data) => {
                    setSearchList(data)
                    setDataList(data)
                    setIsBusy(false)
                })
                .catch((err) => {
                    setIsBusy(false)
                    // message.error("Sorry! " + err);
                });
        }
        else {
            setSearchList([])
            apiGetfileList(folder_id)
                .then((data) => {
                    setSearchList(data)
                    setDataList(data)
                    setIsBusy(false)
                })
                .catch((err) => {
                    setIsBusy(false)
                    // message.error("Sorry! " + err);
                });
        }
    }
    const clickOneFileAction = (info) => {
        setFileInfo(info);
        setShowFile(true)
    };
    return (
        <Spin spinning={isBusy}>
            <div className="folder-search">
                <Row align={'center'}>
                    <Col xs={20} sm={20} md={12} lg={8} xl={8} >
                        <TitleSearch
                            placeholder="Search Keyword"
                            onSearch={handleSearch}
                            onPressEnter={onPressEnter}
                        />
                    </Col>
                </Row>
            </div>
            {showFileList && (
                <div>
                    {/* <div className="file-open-top-bar">
                    </div> */}

                    <Row align={'center'}>
                        <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                            <ButtonWithIcon
                                icon={<img src={icon_backUrl} />}
                                alt=""
                                text="Previous"
                                onClick={() => {
                                    history.goBack()
                                }}
                            />
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                            <div style={{ textAlign: "center" }}>
                                <h1>{folder_info.name}</h1>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 8, order: 3 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                            <div style={{ float: "right", paddingRight: 20 }}>
                                <ButtonWithIcon
                                    icon={<img src={icon_homeUrl} />}
                                    alt=""
                                    text="Home"
                                    onClick={() => {
                                        history.replace("/");
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {searchList == undefined || searchList.length == 0 ? <div style={{ margin: "auto" }}></div> : searchList.map((info, index) => {
                            return <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} onClick={() => {
                                clickOneFileAction(info)
                            }}>
                                <div className="file-item">
                                    <img src={icon_fileUrl} className="file-image"></img>
                                    <div className="file-title">
                                        {info.name}
                                    </div>
                                </div>
                            </Col>
                        })}
                    </Row>
                </div>
            )}
            <CSSTransition
                in={showFile}
                timeout={300}
                classNames="alert"
                unmountOnExit
                onEnter={() => setShowFileList(false)}
                onExited={() => setShowFileList(true)}
            >
                <div>
                    {/* <div className="file-open-top-bar">
                    </div> */}

                    <Row align={'center'}>
                        <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }} md={{ span: 8, order: 1 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                            <ButtonWithIcon
                                icon={<img src={icon_backUrl} />}
                                alt=""
                                text="Previous"
                                onClick={() => {
                                    setShowFile(false)
                                }}
                            />
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                            <div style={{ textAlign: "center", fontSize: 10 }}>
                                <h1>{fileInfo.name}</h1>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 3 }} sm={{ span: 12, order: 3 }} md={{ span: 8, order: 3 }} lg={{ span: 8, order: 3 }} xl={{ span: 8, order: 3 }}>
                            <div style={{ float: "right", paddingRight: 20 }}>
                                <ButtonWithIcon
                                    icon={<img src={icon_homeUrl} />}
                                    alt=""
                                    text="Home"
                                    onClick={() => {
                                        history.replace("/");
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="pdf-reader">
                        <iframe src={fileInfo.file_link}
                            width="100%" height="100%" />
                    </div>
                    {/* <div>
                        <Document file={{ url: fileInfo.file_link, httpHeaders: {}, withCredentials: false }} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <p>
                            Page {pageNumber} of {numPages}
                        </p>
                    </div> */}
                </div>

            </CSSTransition>

        </Spin>
    );
}

export default FileListPage
