import { googleKey } from "config/CONSTANTS";

//export const BASE_URL               = "http://127.0.0.1/WaitIndustryDocumentSystem";
export const BASE_URL               = "https://library.wait.industries/backend/WaitIndustryDocumentSystem";

//User backend base url
export const BASE_URL_USER                  = BASE_URL + "/user";

export const urlGetFolderList               = BASE_URL_USER + "/DashBoard/getFolderList";
export const urlGetFileList                 = BASE_URL_USER + "/DashBoard/getFileList";
export const urlGetSearchfileList           = BASE_URL_USER + "/DashBoard/getSearchfileListInFolder";

export const urlGetFileSearch               = BASE_URL_USER + "/DashBoard/getFileSearch";

export const urlCreateOrder                 = BASE_URL_USER + "/DashBoard/createOrder";

export const urlContactUs                   = BASE_URL_USER + "/DashBoard/contactUs";
export const urlUserLogin                   = BASE_URL_USER + "/login";

