import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlGetFileList, urlGetSearchfileList } from "./CONSTANTS";

export const apiGetfileList = (folder_id) => {
  const url = urlGetFileList;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("folder_id", folder_id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

export const apiGetSearchfileList = (folder_id, searchText) => {
  const url = urlGetSearchfileList;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("folder_id", folder_id);
  formData.append("searchText", searchText);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
};