import React from "react";
import "./MobileAppListWidget.css";
const MobileAppListWidget = () => {
  const onClickStoreUrl = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  return (
    <div>
      <div className="">
        <div className="mobile-app-item custom-button">
          <img
            src="/assets/images/about-us/googleplay.png"
            className="mobile-app-icon"
            onClick={() => {
              onClickStoreUrl(
                "https://play.google.com/store/apps/details?id="
              );
            }}
            alt="alt image"
          />
        </div>
        <div className="mobile-app-item custom-button">
          {/* <img
            src="/assets/images/about-us/appstore.png"
            className="mobile-app-icon"
            onClick={() => {
              onClickStoreUrl(
                "https://apps.apple.com/us/app/"
              );
            }}
            alt="alt image"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default MobileAppListWidget;
