import "utils/dropConsole";
// antd framework style
import "antd/dist/antd.less";
// ROUTER
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "navigation/RouterConfig";

import "./App.css";
import { ProvideAuth } from "navigation/Auth/ProvideAuth";
// Redux
import { Provider } from "react-redux";

import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";

store.subscribe(listener);

function select(state) {
  const { token } = state.userDataStore;
  if (token === undefined || token === "") return "";
  return token;
}

function listener() {
  let token = select(store.getState());
  console.log("state changed", token);

  axios.defaults.headers.common["Content-Type"] =
    "multipart/form-data; charset=UTF-8";
  if (token === "") {
    // console.log("no token");
    delete axios.defaults.headers.common["Authorization"];
  } else {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
}


function App() {
  return (
    <>
      <div>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <ProvideAuth>
              <BrowserRouter>
                <RouterConfig />
              </BrowserRouter>
            </ProvideAuth>
          </PersistGate>
        </Provider>
      </div>
    </>
  );
}

export default App;

